<template>
  <el-dialog title="设置置顶" :visible.sync="openDialog" width="360px" class="dialog-vertical"
    @close="cancel('editForm')">
    <el-form size="medium" :model="editForm" :rules="rules" label-position="right" label-width="85px"
      class="small-form" ref="editForm" @submit.native.prevent>
      <el-form-item label="开始时间：" prop="start_top_time">
        <el-date-picker v-model="editForm.start_top_time" :picker-options="pickerStartOptions" type="datetime"
          value-format="timestamp" placeholder="请选择开始时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间：" prop="end_top_time">
        <el-date-picker v-model="editForm.end_top_time" :picker-options="pickerEndOptions" type="datetime"
          value-format="timestamp" placeholder="请选择结束时间">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" @click="openDialog = false">取消</el-button>
      <el-button size="small" type="primary" @click="submit('editForm')" :loading="saveLoading">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { setTop } from "../../api/activity-list/information";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [Number, String, Array],
      default: 0,
    },
    updateList: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      saveLoading: false,
      editForm: {
        start_top_time: "",
        end_top_time: "",
      },
      rules: {
        start_top_time: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        end_top_time: [
          { required: true, message: "请选择结束时间", trigger: "change" },
        ],
      },
      // 开始时间选择范围
      pickerStartOptions: {
        disabledDate: (time) => {
          return this.selectableDate(time, 0);
        },
      },
      // 结束时间选择范围
      pickerEndOptions: {
        disabledDate: (time) => {
          return this.selectableDate(time, 1);
        },
      },
    };
  },
  methods: {
    // 设置时间选择范围
    selectableDate(time, type) {
      if (type) {
        if (this.editForm.start_top_time) {
          return time.getTime() < this.editForm.start_top_time;
        } else {
          return false;
        }
      } else {
        if (this.editForm.end_top_time) {
          return time.getTime() > this.editForm.end_top_time;
        } else {
          return false;
        }
      }
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const formData = { ...this.editForm };
          const data = {
            start_top_time: formData.start_top_time / 1000,
            end_top_time: formData.end_top_time / 1000,
            activity_article_ids: this.id,
            is_top: 1,
          };
          setTop(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.updateList();
              this.openDialog = false;
              this.saveLoading = false;
            })
            .catch(() => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style>
</style>
