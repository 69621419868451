import api from "@/base/utils/request";

// 关联文章列表
export const CheckList = data => {
  return api({
    url: "/admin/cyc_activity/activity/articles",
    method: "post",
    data
  });
};

// 删除关联文章
export const DelArticles = data => {
  return api({
    url: "/admin/cyc_activity/activity/delArticles",
    method: "post",
    data
  });
};

// 置顶关联文章
export const setTop = data => {
  return api({
    url: "/admin/cyc_activity/activity/topArticles",
    method: "post",
    data
  });
};

// 添加收藏会员
export const collectUser = data => {
  return api({
    url: "/admin/cyc/brand/userCollect",
    method: "post",
    data
  });
};

// 文章来源选项数据
export const sourceOptions = data => {
  return api({
    url: "/admin/portal/source/all",
    method: "post",
    data
  });
};

// 文章分类选项数据
export const articleCategorys = data => {
  return api({
    url: "/admin/portal/category/allShow",
    method: "post",
    data
  });
};






// 获取文章/活动列表
export const postedList = data => {
  return api({
    url: "/admin/cyc/brand/posted",
    method: "post",
    data
  });
};
// 添加关联文章
export const AddList = data => {
  return api({
    url: "/admin/cyc_activity/activity/addArticles",
    method: "post",
    data
  });
};

// 删除文章/活动
export const DeleteList = data => {
  return api({
    url: "/admin/cyc/brand/deleteBrand",
    method: "post",
    data
  });
};

// 获取所有来源
export const AllSource = data => {
  return api({
    url: "/admin/portal/source/all",
    method: "post",
    data
  });
};

// 绑定社区话题
export const bindCircleTopic = data => {
  return api({
    url: "/admin/cyc_activity/activity/bindCircleTopic",
    method: "post",
    data
  });
};

// 获取社区话题
export const circleTopic = data => {
  return api({
    url: "/admin/cyc_activity/activity/circleTopic",
    method: "post",
    data
  });
};
