<template>
  <div class="list-page">
    <list-tabs
      v-model="filterForm.tab"
      :tabs="tabArr"
      :isLoading="loading"
      @tab-click="handleClick"
    >
    </list-tabs>
    <!-- 切换到话题不显示列表 -->
    <div v-show="filterForm.tab !== 'topic'">
      <InformationFilter :filterForm="filterForm" :uploadFilter="ok" />

      <div class="list-content" v-loading="loading">
        <el-button
          class="list-btn"
          type="primary"
          size="small"
          @click="handleAdd"
          >添加</el-button
        >
        <el-button
          v-if="filterForm.tab !== 'collect'"
          class="list-btn"
          type="danger"
          size="small"
          @click="handleDel"
          >删除</el-button
        >
        <el-button
          v-if="filterForm.tab === 'article'"
          class="list-btn"
          type="default"
          size="small"
          @click="handleSetTop"
          >置顶</el-button
        >
        <el-table
          :data="tbody"
          class="thead-light"
          stripe
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @sort-change="sortChange"
        >
          <!-- 勾选-->
          <el-table-column
            fixed
            type="selection"
            width="55"
            v-if="filterForm.tab !== 'collect'"
          >
          </el-table-column>
          <!-- 操作 -->
          <el-table-column
            label="操作"
            :width="filterForm.tab === 'article' ? '130' : '70'"
            fixed="right"
            v-if="filterForm.tab !== 'collect'"
          >
            <template slot-scope="scope">
              <el-button
                v-if="filterForm.tab === 'article' && !scope.row.is_top"
                size="small"
                @click="setTopRow(scope.row)"
                type="text"
                >置顶</el-button
              >
              <el-button
                v-if="filterForm.tab === 'article' && scope.row.is_top"
                size="small"
                @click="cancelSetTopRow(scope.row)"
                type="text"
                >取消置顶</el-button
              >
              <el-button size="small" @click="deleteRow(scope.row)" type="text"
                >删除</el-button
              >
            </template>
          </el-table-column>
          <template v-for="(th, index) in thead[filterForm.tab]">
            <el-table-column
              show-overflow-tooltip
              :prop="th.prop"
              :label="th.label"
              :min-width="th.minWidth || ''"
              :sortable="th.sortable"
              :key="index"
            >
              <template slot-scope="scope">
                <!-- 活动时间 -->
                <span v-if="th.prop === 'activity_start_time'">{{
                  scope.row.activity_start_time +
                  " - " +
                  scope.row.activity_end_time
                }}</span>
                <!-- 头像 -->
                <el-image
                  class="tabel-avatar"
                  v-else-if="th.prop === 'avatar'"
                  :src="scope.row[th.prop]"
                  fit="cover"
                >
                </el-image>
                <!-- 置顶状态 -->
                <span v-else-if="th.prop === 'is_top'">{{
                  scope.row[th.prop] ? "已置顶" : "未置顶"
                }}</span>
                <!-- 普通 -->
                <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <Pagination
          :total="pageData.total"
          :page="pageData.current_page"
          :change="changPage"
        />

        <!-- 选择相册弹窗 -->
        <SelectArticleDialog
          v-model="openHandleList"
          @change="changeArticleSelect"
        ></SelectArticleDialog>
        <set-top-dialog
          v-model="openSetTopDialog"
          :id="current_id"
          :updateList="refreshList"
        ></set-top-dialog>
        <SelectMember
          v-if="filterForm.tab === 'collect'"
          v-model="openSelectMember"
          :Members="tbody"
          selectedType="check"
          @changeSelect="handleSelect"
        />
      </div>
    </div>
    <div v-show="filterForm.tab === 'topic'">
      <div class="modular">
        <p class="modular-title">关联话题</p>
        <div class="modular-content">
          <el-form class="small-form" @submit.native.prevent>
            <el-form-item>
              <el-button
                type="primary"
                style="margin-right: 10px"
                v-loading="loading"
                @click="selectTopic"
                >{{ topicData.name ? topicData.name : "选择话题" }}</el-button
              >
              <el-link v-if="topicData.id" @click="goTopic" :underline="false"
                >查看话题</el-link
              >
              <el-link
                v-if="topicData.id"
                :underline="false"
                style="margin-left: 10px"
                @click="changeDataSelect([], 'del')"
                >删除话题</el-link
              >
              <p class="info">
                <i class="el-icon-warning" />没有关联话题时，用户无法发布帖子
              </p>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <!-- <FixedActionBar>
      <el-button @click="GoBack">返回
      </el-button>
    </FixedActionBar> -->
    <!-- 选择话题弹窗 -->
    <SelectDataDialog
      v-model="showSelectTopic"
      @change="changeDataSelect"
      componentType="st-circle-topic"
    ></SelectDataDialog>
  </div>
</template>

<script>
import InformationFilter from "../../components/ActivityList/InformationFilter.vue";
import SelectArticleDialog from "../../components/ActivityList/SelectArticleDialog.vue";
import {
  CheckList,
  DelArticles,
  setTop,
  collectUser,
  AddList,
  bindCircleTopic,
  circleTopic,
} from "../../api/activity-list/information";

import FixedActionBar from "@/base/layout/FixedActionBar";
import Pagination from "@/base/components/Default/Pagination";

import SetTopDialog from "../../components/ActivityList/SetTopDialog.vue";
import SelectMember from "@/modules/common/components/MemberSelector";

import SelectDataDialog from "../../components/ActivityList/SelectDataDialog";
export default {
  components: {
    FixedActionBar,
    InformationFilter,
    Pagination,
    SetTopDialog,
    SelectMember,
    SelectArticleDialog,
    SelectDataDialog,
  },
  data() {
    return {
      pageData: {},
      tabArr: [
        { label: "文章", name: "article" },
        { label: "话题", name: "topic" },
      ],
      filterForm: {
        activity_id: this.$route.params.id || this.$route.query.id,
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        is_top: -1,
        article_title: "", //文章标题
        article_name: "", // 文章作者
        source_name: "", // 文章来源
        category_id: -1, // 文章分类
        article_type: -1, // 文章类型
        page_size: 15,
        tab: "article",
      },
      // 筛选缓存
      filters: {
        article: null,
        activity: null,
      },
      tbody: [],
      loading: false, //加载
      thead: {
        article: [
          { label: "标题", prop: "article_title", minWidth: 200 },
          { label: "来源", prop: "source_name", minWidth: 100 },
          { label: "分类", prop: "category_name", minWidth: 110 },
          { label: "作者", prop: "author_name", minWidth: 110 },
          { label: "类型", prop: "type_text", minWidth: 80 },
          {
            label: "发布时间",
            prop: "post_time",
            minWidth: 160,
          },
          { label: "状态", prop: "is_top", minWidth: 110 },
          {
            label: "置顶时间",
            prop: "start_top_time",
            minWidth: 160,
            sortable: true,
          },
        ],
      },
      selectData: {
        id: this.$route.params.id, // 活动id
        mark: 0, // 添加或删除
        status: 0, //添加或删除的类型
      },
      selectArr: [], //勾选的数组id
      validatorArr: [], //置顶条件验证
      current_id: 0, // 文章置顶设置id
      openHandleList: false, //控制活动文章选择弹窗显示隐藏
      openSetTopDialog: false,
      openSelectMember: false,
      showSelectTopic: false, // 是否显示关联话题
      topicData: {
        name: "",
        id: "",
      }, // 话题对象 name,id
    };
  },
  methods: {
    // 获取列表数据
    getCheckList(requestData) {
      if (requestData.tab === "topic") {
        this.getCircleTopic();
        return;
      }
      this.loading = true;
      this.pageData = {};
      CheckList(requestData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
        this.validatorArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.activity_article_id);
        });
        if (this.filterForm.tab === "article") {
          this.validatorArr = [];
          selection.forEach((item) => {
            this.validatorArr.push(item.is_top);
          });
        }
      }
    },
    // 添加
    handleAdd() {
      if (this.filterForm.tab !== "collect") {
        this.selectData.status = this.filterForm.tab === "article" ? 1 : 0;
        this.openHandleList = true;
      } else {
        this.openSelectMember = true;
      }
    },
    //批量置顶
    handleSetTop() {
      if (this.selectArr.length) {
        if (this.validatorArr.findIndex((isTop) => isTop === 1) !== -1) {
          this.$message.error("选中数据中存在已置顶数据");
          return;
        }
        this.current_id = this.selectArr;
        this.openSetTopDialog = true;
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 单项置顶
    setTopRow(row) {
      this.current_id = [row.activity_article_id];
      this.openSetTopDialog = true;
    },
    // 取消置顶
    cancelSetTopRow(row) {
      const data = {
        start_top_time: 0,
        end_top_time: 0,
        activity_article_ids: [row.activity_article_id],
        is_top: 0,
      };
      this.loading = true;
      setTop(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将选中数据删除吗？", "提示", {
            type: "error",
          })
          .then((res) => {
            const data = {};
            data.activity_article_ids = this.selectArr;
            this.handleDelete(data);
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表删除
    deleteRow(row) {
      this.$msgbox
        .confirm("确定要将此数据删除吗？", "提示", {
          type: "error",
        })
        .then((res) => {
          const data = {};
          data.activity_article_ids = [row.activity_article_id];
          this.handleDelete(data);
        })
        .catch((err) => {});
    },
    // 数据删除
    handleDelete(data) {
      DelArticles(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch((err) => {});
    },
    // 获取选择会员
    handleSelect(e) {
      this.loading = true;
      const user_id = [];
      e.forEach((el) => {
        user_id.push(el.user_id);
      });
      const data = {
        id: this.$route.params.id,
        user_id,
      };
      collectUser(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.getCheckList(this.filterForm);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getCheckList(this.filterForm);
    },
    // 切换tab栏
    handleClick(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name];
      } else {
        this.filterForm = {
          activity_id: this.$route.params.id,
          start_time: -1, //开始时间
          end_time: -1, //结束时间
          is_top: -1,
          article_title: "", //文章标题
          article_name: "", // 文章作者
          source_name: "", // 文章来源
          category_id: -1, // 文章分类
          article_type: -1, // 文章类型
          page_size: 15,
          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页数据量
        };
      }
      this.getCheckList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getCheckList({ ...this.filterForm, ...pageData });
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getCheckList(pageData);
    },
    // 刷新列表
    refreshList() {
      this.getCheckList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 返回
    GoBack() {
      this.$router.push({ name: "CycBrandList" });
    },
    // 选择文章回调
    changeArticleSelect(ids) {
      console.log(ids);
      this.loading = true;
      AddList({ activity_id: this.$route.params.id, article_ids: ids })
        .then((res) => {
          this.$message.success("添加成功");

          this.refreshList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 关联话题
    selectTopic() {
      this.showSelectTopic = true;
    },
    // 选择关联话题
    changeDataSelect(selectArr, type) {
      console.log(selectArr);
      let postData = {};
      if (type === "del") {
        postData = {
          activity_id: this.$route.params.id,
          circle_topic_id: "",
        };
      } else {
        postData = {
          activity_id: this.$route.params.id,
          circle_topic_id: selectArr[0].id,
        };
      }

      bindCircleTopic(postData)
        .then((res) => {
          console.log(res);
          this.$message.success("操作成功");
          this.getCircleTopic();
        })
        .catch((err) => {
          // this.$message.success('绑定话题成功')
        });
    },
    // 获取有无绑定的话题
    getCircleTopic() {
      this.loading = true;
      circleTopic({ activity_id: this.$route.params.id })
        .then((res) => {
          if (res.data) {
            this.topicData = res.data;
          } else {
            this.topicData = {
              name: "",
              id: "",
            };
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 跳转到发帖列表
    goTopic() {
      this.$router.push({
        name: "ActivityPostList",
        params: {
          topic_id: this.topicData.id,
          id: this.$route.params.id,
          type: this.$route.params.type,
        },
      });
    },
  },
  created() {
    this.getCheckList(this.filterForm); //获取列表数据
    this.getCircleTopic();
  },
};
</script>

<style lang="scss" scoped>
.list-content {
  margin-top: 20px;
}
</style>
